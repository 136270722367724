<template>
  <div id="misc">
    <img
      class="misc-mask"
      height="226"
      :src="require(`@/assets/images/misc/misc-mask-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
    />
    <div class="page-title text-center px-5">
      <div>
        <v-icon size="120">
          {{ icons.mdiAccountCancel }}
        </v-icon>
      </div>
      <h2 class="text-3xl font-weight-semibold text--primary d-flex align-center justify-center">
        <span class="me-2">Usuario no autorizado</span>
      </h2>
      <p class="text-md">
        Por favor póngase en contacto con el administrador del sistema.
      </p>
      <v-btn
        v-if="true"
        color="primary"
        type="button"
        @click.prevent="logout"
      >
        Ir al login
      </v-btn>
      <!-- :to="{name: 'loggedout'}" -->
      <div class="d-flex justify-center my-10">
        <div
          class="ma-auto"
          style="max-width: 300px"
        >
        </div>
        <v-overlay
          absolute
          :value="loading"
        >
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </v-overlay>
      </div>
    </div>
  </div>
</template>

<script>
import { mdiLockOutline, mdiAccountCancel } from '@mdi/js'
import { ref } from '@vue/composition-api'

export default {
  // props: {
  //   operationCode: {
  //     type: String,
  //     required: true,
  //   },
  //   email: {
  //     type: String,
  //     required: true,
  //   },
  // },

  setup() {
    return {
      loading: ref(false),
      logout: async () => {
        localStorage.clear()

         window.location.replace(process.env.VUE_APP_API_AZURE_AD+'/MicrosoftIdentity/Account/SignOut')

       // window.location.replace('https://identity.guayaquil.gob.ec/MicrosoftIdentity/Account/SignOut')
      },
      icons: {
        mdiLockOutline,
        mdiAccountCancel,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/misc.scss';
</style>
